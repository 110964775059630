<template>
  <div>
    <div class="row">
      <div class="col-lg-6">

        <div class="form-group ">
          <input type="text" id="payment-card-number" v-model="cari" class="form-control" placeholder="search username">
        </div>
      </div>
      <div class="col-lg-4">
      </div>

      <div class="col-lg-2">
        <b-button class="btn-primary mb-1" block v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.AddLevel
          variant="primary">
          <i class="fa fa-plus"></i>
          Add Level
        </b-button>
      </div>
    </div>
    <CardList @LoadLevelData = LoadLevel :IndukLevel = IndukLevel></CardList>
    <AddLevel :IndukLevel=IndukLevel @LoadLevel=LoadLevel></AddLevel>

  </div>
</template>

<script>
  import {
    mapMutations,
    mapState
  } from 'vuex'
  import Base from '@/config/Mixins_base';
  import axios from '../../../config/Axios';

  import Ripple from 'vue-ripple-directive'

  import {
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BButton,
    VBModal,


  } from 'bootstrap-vue'
  import CardList from './component/CardList.vue'
  import AddLevel from './component/AddLevel.vue'
  export default {
    data() {
      return {
        currentPage: 1,
        rows: 0,
        cari: "",
        IndukLevel: {},

      }
    },

    mixins: [Base],
    components: {
      BDropdown,
      BDropdownItem,
      BPagination,
      BModal,
      BButton,
      VBModal,
      CardList,
      AddLevel,
    },
    directives: {
      'b-modal': VBModal,
      Ripple,
    },
    computed: {
      ...mapState({
        rsLevel: state => state.aclLevel.rsLevel,
      })
    },
    watch: {
      cari(newData, old) {
        if (newData != NaN) {
          this.loadData();
        }
      }
    },
    mounted() {
      this.indukLevelData();
      this.loadData();
    },
    methods: {
      ...mapMutations({
        setRsLevel: 'setRsLevel',
      }),
      LoadLevel() {
        this.loadData();
        this.indukLevelData();
      },
      async loadData(offset, cari) {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/acl/level/list',
            data: {
              offset: self.currentPage,
              cari: self.cari
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.setRsLevel(response.data.result);
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },

      async indukLevelData() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/acl/level/induk',
            data: {
              offset: self.currentPage,
              cari: self.cari
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.IndukLevel = response.data.result;
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
    }

  }
</script>

<style>

</style>